/* istanbul ignore file */

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import commentsFactory from '../../../../../common/components/Comments/factory';
import { hasNextPage } from '../Pager/shared/helpers';
import { setCount as setCommentsCountAction } from '../../../../../shared/actions/comment';
import Comment from '../Comments/components/Comment';
import CommentSort from '../Comments/components/CommentSort';
import Commenting from '../Comments/components/Commenting';
import Icon from '../Icon';
import {
  PAGER_TYPE_LAZY_LOADING as pagerType,
  default as Pager,
} from '../Pager';
import { GET_COMMENTS } from '../Comments/queries';
import styles from './styles.legacy.css';
import grid from '../../../../../common/assets/styles/grid.legacy.css';

/**
 *  We implemented the lazyLoader in different way on SI
 *  thats why we pass the pager as jsx to the factory.
 */
const getPagerJsx = ({
  currentPage,
  updatePage,
  itemsCount,
  itemsPerPage,
  text,
  isLoading,
}): ReactElement => (
  <Pager
    component={pagerType}
    isLoading={isLoading}
    loadMore={() => {
      updatePage(currentPage + 1);
    }}
    hasMoreResults={hasNextPage(currentPage, itemsCount, itemsPerPage)}
  >
    {text}
  </Pager>
);
export default commentsFactory({
  grid,
  Icon,
  Comment,
  Commenting,
  CommentSort,
  Pager,
  pagerType,
  setCommentsCountAction,
  GET_COMMENTS,
  pager: getPagerJsx,
  styles: {
    Container: '',
    Column: classNames(grid.ColXs24),
    Icon: styles.Icon,
    Inner: styles.Inner,
    Pager: styles.Pager,
    Title: styles.Title,
    Counter: styles.Counter,
  },
});
