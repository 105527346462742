import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import renderTopic from '../../../shared/helpers/topic';
import { getRestrictedClassName } from '../../../shared/helpers/withHelmet';
import { resetHeaderData, setHeaderData } from '../../../shared/actions/header';
import TestFragment from '../../../shared/tests/components/TestFragment';
import { VideoDetailFactoryOptions, VideoProps } from './typings';
import classNames from 'classnames';
import grid from '../../assets/styles/grid.legacy.css';

type VideoPropsInner = VideoProps & {
  resetHeaderData: Function;
  setHeaderData: Function;
};

type VideoQueryProps = {
  environment: Route & {
    routeByPath: {
      object: {
        newer?: VideoConnection;
        older?: VideoConnection;
      };
    };
  };
};

const VideoRecommendations = ({
  appMessageLoadingVideos,
  appMessageMoreVideos,
  appMessageVideosOfChannel,
  channelType,
  getRecommendationItems,
  location,
  publication,
  styles,
  video,
  videoBlogChannelType,
  videoRecommendationsQuery,
  videosRouteUrl,
  ErrorMessage,
  Recommendations,
}) => {
  const gqlVariables = {
    publication,
    path:
      location &&
      location.pathname.substr(1) +
        ((!__TESTING__ && '?videoRecommendations') || ''),
    termId:
      channelType === videoBlogChannelType ? Number(video.channel.tid) : null,
  };

  const { data, loading, error } = useQuery<VideoQueryProps>(
    videoRecommendationsQuery,
    {
      variables: gqlVariables,
    },
  );

  const object = data?.environment?.routeByPath?.object;

  const recommendationItems: Array<RecommendationListItem> =
    getRecommendationItems(
      object?.newer?.edges || [],
      object?.older?.edges || [],
    );

  return (
    <div
      className={styles.RecommendationsWrapper}
      data-testid="recommendations-query-wrapper"
    >
      {loading && (
        <div className={styles.Loading}>{appMessageLoadingVideos}</div>
      )}

      {!loading && error && !object && __DEVELOPMENT__ && (
        <ErrorMessage msg={`Apollo <Query> component error: ${error}`} />
      )}

      {(!loading && object && (
        <TestFragment data-testid="recommendations-wrapper">
          <Recommendations
            items={recommendationItems}
            title={
              channelType === videoBlogChannelType
                ? appMessageVideosOfChannel
                : appMessageMoreVideos
            }
            titleLinkPath={
              channelType === videoBlogChannelType
                ? video?.channel?.landingPage?.preferredUri
                : `/${videosRouteUrl}`
            }
            trackingOrigin="bottom"
          />
        </TestFragment>
      )) ||
        null}
    </div>
  );
};

const VideoFactory = ({
  origin,
  styles,
  videosRouteUrl,
  ArticleAlerts,
  ArticleHeader,
  videoBlogChannelType,
  Breadcrumbs,
  ErrorMessage,
  getRecommendationItems,
  LinkButton,
  EditButtons,
  OverviewPageHeader,
  Paragraphs,
  Recommendations,
  SocialBar,
  videoRecommendationsQuery,
  publication,
  appMessageVideosOfChannel = 'Weitere Videos aus der Serie',
  appMessageMoreVideos = 'Weitere Videos',
  appMessageLoadingVideos = 'Videos werden geladen...',
}: VideoDetailFactoryOptions) => {
  const Video = ({
    video,
    location,
    resetHeaderData,
    setHeaderData,
  }: VideoPropsInner): ReactElement => {
    const {
      channel,
      shortTitle,
      preferredUri,
      title,
      lead,
      gcid,
      __typename,
    }: Video = video;

    const channelType: string = video?.channel?.channelType || '';

    useEffect(() => {
      setHeaderData({
        articleData: {
          gcid,
          title,
          shortTitle,
          lead,
          channel,
          preferredUri,
        },
        contentType: __typename,
      });

      return () => {
        resetHeaderData();
      };
    }, [
      __typename,
      channel,
      gcid,
      preferredUri,
      resetHeaderData,
      setHeaderData,
      title,
      shortTitle,
      lead,
    ]);

    if (!video) {
      return null;
    }
    const topicList = video?.relatedTopics?.edges;

    const activeMenuTrail = video?.activeMenuTrail
      ? {
          ...JSON.parse(JSON.stringify(video?.activeMenuTrail)),
        }
      : null;

    // add "Videos" to the breadcrumbs at the last position before the h1 title
    Array.isArray(activeMenuTrail?.edges) &&
      activeMenuTrail.edges.length >= 2 &&
      activeMenuTrail.edges.splice(activeMenuTrail.edges.length - 1, 0, {
        node: {
          label: 'Videos',
          link: `/${videosRouteUrl}`,
        },
      });

    return (
      <TestFragment data-testid="video-container">
        {EditButtons && (
          <EditButtons
            editContentUri={video.editContentUri}
            editRelationUri={video.editRelationUri}
          />
        )}
        {(video?.preferredUri && activeMenuTrail && Breadcrumbs && (
          <div className={styles.BreadcrumbsContainer}>
            <TestFragment data-testid="video-breadcrumbs-wrapper">
              <Breadcrumbs
                pageUrl={video.preferredUri}
                items={activeMenuTrail}
              />
            </TestFragment>
          </div>
        )) ||
          null}

        <div className={getRestrictedClassName(video.__typename)}>
          {(channelType === videoBlogChannelType && OverviewPageHeader && (
            <TestFragment data-testid="overview-page-header-wrapper">
              <OverviewPageHeader
                title={video?.channel?.settings?.title || 'Videos'}
                lead={video?.channel?.settings?.lead || ''}
                headerImage={video?.channel?.settings?.headerImage || null}
              />
            </TestFragment>
          )) ||
            null}
          <div
            className={styles.Container}
            data-testid="video-detail-page-wrapper"
          >
            <div className={styles.Row}>
              <div className={styles.VideoContainer}>
                <Paragraphs
                  pageBody={[video]}
                  origin={origin}
                  hasContainer={false}
                  // this key is needed to force the component to re-render, otherwise the video will not be reloaded when using "history back" on video detail pages on SI
                  key={`video-id-${video.brightcoveId}`}
                />
                {ArticleHeader && (
                  <ArticleHeader
                    shortTitle={video.shortTitle || ''}
                    title={video.title || ''}
                    lead={video.caption || ''}
                  />
                )}
                {(video?.preferredUri && SocialBar && (
                  <div className={styles.SocialBarWrapper}>
                    <SocialBar />
                  </div>
                )) ||
                  null}
              </div>
            </div>

            {topicList?.length > 0 && (
              <div
                className={classNames(
                  styles.ArticleAlertsWrapper,
                  grid.HideForPrint,
                )}
              >
                <div className={styles.ArticleAlertsInnerWrapper}>
                  <strong className={styles.Topic}>Topics: </strong>
                  {topicList.map((topic, index) =>
                    renderTopic(topic, index, styles.Topic),
                  )}
                </div>
              </div>
            )}

            {(video?.keywords?.edges &&
              Array.isArray(video.keywords.edges) &&
              video.keywords.edges.length > 0 &&
              ArticleAlerts && (
                <div
                  className={classNames(
                    styles.ArticleAlertsWrapper,
                    grid.HideForPrint,
                  )}
                  data-testid="video-screen-article-alerts-wrapper"
                >
                  <div className={styles.ArticleAlertsInnerWrapper}>
                    <ArticleAlerts items={video.keywords.edges} />
                  </div>
                </div>
              )) ||
              null}

            {(video?.channel?.tid &&
              Recommendations &&
              videoRecommendationsQuery &&
              getRecommendationItems && (
                <VideoRecommendations
                  appMessageLoadingVideos={appMessageLoadingVideos}
                  appMessageMoreVideos={appMessageMoreVideos}
                  appMessageVideosOfChannel={appMessageVideosOfChannel}
                  channelType={channelType}
                  getRecommendationItems={getRecommendationItems}
                  location={location}
                  publication={publication}
                  styles={styles}
                  video={video}
                  videoBlogChannelType={videoBlogChannelType}
                  videoRecommendationsQuery={videoRecommendationsQuery}
                  videosRouteUrl={videosRouteUrl}
                  ErrorMessage={ErrorMessage}
                  Recommendations={Recommendations}
                />
              )) ||
              null}

            <div className={styles.CTAWrapper}>
              <LinkButton link={{ path: `/${videosRouteUrl}` }}>
                {appMessageMoreVideos}
              </LinkButton>
            </div>
          </div>
        </div>
      </TestFragment>
    );
  };

  const mapDispatchToProps: Record<string, any> = {
    setHeaderData,
    resetHeaderData,
  };

  return connect(null, mapDispatchToProps)(Video);
};

export default VideoFactory;
