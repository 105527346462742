import compose from 'recompose/compose';
import withHandlers from 'recompose/withHandlers';
import withState from 'recompose/withState';
import {
  GLOBAL_SEARCH_SORT_ASC,
  GLOBAL_SEARCH_SORT_DESC,
} from '../constants/globalSearch';
import namedComponent from './namedComponent';

export type WithComments = {
  commentsSortOrder: string;
  setCommentsSortOrder: Function;
  toggleCommentsSortOrder: Function;
};

const extendWithHandlers: Function = withHandlers({
  toggleCommentsSortOrder:
    ({ commentsSortOrder, setCommentsSortOrder }) =>
    (): void => {
      setCommentsSortOrder(
        commentsSortOrder === GLOBAL_SEARCH_SORT_DESC
          ? GLOBAL_SEARCH_SORT_ASC
          : GLOBAL_SEARCH_SORT_DESC,
      );
    },
});

export default (Component: Function): Function =>
  compose(
    namedComponent('withCommentsHandlers'),
    withState<Record<string, any>, Record<string, any>, string>(
      'commentsSortOrder',
      'setCommentsSortOrder',
      GLOBAL_SEARCH_SORT_DESC,
    ),
    extendWithHandlers,
  )(Component);
