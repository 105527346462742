import {
  PianoStateAction,
  SET_PIANO_BROWSER_META_DATA,
  SET_PIANO_EXTERNAL_SUBSCRIPTION,
  SET_PIANO_METER_ACTIVE_DATA,
  SET_PIANO_PAGE_META_DATA,
  SET_PIANO_USER_META_DATA,
  SET_PIANO_WEBINAR_ACCESS,
} from '../actions/piano';

export const initialState: PianoState = {
  pageMetadata: {
    channelsHierarchy: [],
    contentType: null,
    publication: '',
    isPrintArticle: false,
    isNativeContent: false,
    pathname: '/',
    publicationDate: '',
    restrictionStatus: null,
    section: '',
    tags: null,
    gcid: null,
    subType: '',
    mainChannel: null,
    page: null,
    webinarId: null,
    notInitialized: false, // we should set it to true for all publications
    cliffhangerTitle: null,
    cliffhangerBulletpoints: [],
  },
  userMetadata: {
    idToken: '',
    externalSubscription: '',
    initialAuthRequest: false,
  },
  browserMetadata: {
    browserMode: 'normal',
    isPushNotificationsSupported: false,
    isPushNotificationsEnabled: false,
    notificationsPermission: 'default',
  },
  isAccessGranted: false,
  webinarAccessGranted: false,
};

const PianoStateReducer = (
  state: PianoState = initialState,
  action: PianoStateAction<
    | PianoPageMetadata
    | PianoUserMetadata
    | PianoBrowserMetadata
    | boolean
    | string
  >,
): PianoState => {
  switch (action.type) {
    case SET_PIANO_PAGE_META_DATA:
      const {
        channelsHierarchy,
        contentType,
        publication,
        isNativeContent,
        pathname,
        publicationDate,
        restrictionStatus,
        section,
        tags,
        isPrintArticle,
        subType,
        gcid,
        mainChannel,
        webinarId,
        page,
        cliffhangerTitle,
        cliffhangerBulletpoints,
      } = action.payload as PianoPageMetadata;

      return {
        ...state,
        pageMetadata: {
          channelsHierarchy,
          contentType,
          publication,
          isNativeContent,
          pathname,
          publicationDate,
          restrictionStatus,
          section,
          tags,
          isPrintArticle,
          subType,
          gcid,
          mainChannel,
          webinarId,
          notInitialized: false,
          page,
          cliffhangerTitle,
          cliffhangerBulletpoints,
        },
        isAccessGranted: false,
        webinarAccessGranted: false,
      };
    case SET_PIANO_USER_META_DATA:
      return {
        ...state,
        userMetadata: {
          idToken:
            (action.payload as PianoUserMetadata).idToken ||
            initialState.userMetadata.idToken,
          externalSubscription:
            (action.payload as PianoUserMetadata).externalSubscription ||
            state.userMetadata.externalSubscription ||
            initialState.userMetadata.externalSubscription,
          initialAuthRequest:
            (action.payload as PianoUserMetadata).initialAuthRequest === true ||
            initialState.userMetadata.initialAuthRequest,
        },
      };
    case SET_PIANO_BROWSER_META_DATA:
      return {
        ...state,
        browserMetadata: {
          browserMode: (action.payload as PianoBrowserMetadata).browserMode,
          isPushNotificationsSupported: (action.payload as PianoBrowserMetadata)
            .isPushNotificationsSupported,
          isPushNotificationsEnabled: (action.payload as PianoBrowserMetadata)
            .isPushNotificationsEnabled,
          notificationsPermission: (action.payload as PianoBrowserMetadata)
            .notificationsPermission,
        },
      };
    case SET_PIANO_METER_ACTIVE_DATA:
      return {
        ...state,
        isAccessGranted: action.payload as boolean,
      };
    case SET_PIANO_WEBINAR_ACCESS:
      return {
        ...state,
        webinarAccessGranted: action.payload as boolean,
      };
    case SET_PIANO_EXTERNAL_SUBSCRIPTION:
      return {
        ...state,
        userMetadata: {
          idToken: state.userMetadata.idToken,
          externalSubscription: action.payload as string,
          initialAuthRequest: state.userMetadata.initialAuthRequest,
        },
      };
    default:
      return state;
  }
};

export default PianoStateReducer;
