/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  CHANNEL_TYPE_BLOGS,
  DEFAULT_PUBLICATION,
  OVERVIEW_VISIBLE_TYPE_BLOGS,
  ROUTE_SI_BLOGS,
  SI_CHANNELS_VOCABULARY,
} from '../../constants';
import { PAGE_SIZE } from './constants';
import { GET_BLOGS_PAGE } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location }) => {
    const page = location.query?.page || 1;
    return {
      query: GET_BLOGS_PAGE,
      variables: {
        publication: DEFAULT_PUBLICATION,
        path: ROUTE_SI_BLOGS,
        vid: SI_CHANNELS_VOCABULARY,
        channelType: [CHANNEL_TYPE_BLOGS],
        overviewPageVisibility: [OVERVIEW_VISIBLE_TYPE_BLOGS],
        overviewPageSize: page === 1 ? PAGE_SIZE + 1 : PAGE_SIZE,
        overviewPageOffset: (page - 1) * PAGE_SIZE,
      },
      context: {
        raschApolloService: true,
      },
    };
  },
};
