import React from 'react';
import { useSelector } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { linkFromSponsor } from '../../../../../common/components/SponsorBanner/helpers';
import locationStateSelector from '../../../../shared/selectors/locationStateSelector';
import withHelmet from '../../../../shared/decorators/withHelmet';
import useImpressionTracking from '../../../../../shared/hooks/useImpressionTracking';
import InView from '../../../../../common/components/InView';
import Link from '../../../../../common/components/Link';
import Picture from '../../../../../common/components/Picture';
import Breadcrumbs from '../../components/Breadcrumbs';
import EditButtons from '../../components/EditButtons';
import Head from '../../components/Head';
import Paragraphs from '../../components/Paragraphs';
import {
  ADVERTISING_TYPE_LONGFORM,
  NATIVE_ADVERTISING_CONTENT_TYPE,
  PAGE_TYPE_MARKETING,
  PAGE_TYPE_MARKETING_DEFAULT_HEADER,
} from '../../../../../shared/constants/content';
import { STYLE_SCALEH_120 } from '../../../../../shared/constants/images';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { PAGE_SCREEN_DEFAULT, PAGE_SCREEN_MARKETING_TYPE } from './constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import sections from '../../../../../common/assets/styles/sections.legacy.css';
import styles from './styles.legacy.css';
import { PageScreenProps } from './typings';

type PageScreenPropsInner = PageScreenProps;

const configIsVisible: InViewConfig = {
  rootMargin: `-${150}px 0px 0px 0px`,
};

const PageScreen = ({ pageScreen, location }: PageScreenPropsInner) => {
  const isMarketingPage = [
    PAGE_TYPE_MARKETING,
    PAGE_TYPE_MARKETING_DEFAULT_HEADER,
    ADVERTISING_TYPE_LONGFORM,
  ].includes(pageScreen.subtypeValue);

  const screenReady = useSelector(
    (state) => locationStateSelector(state).screenReady,
  );

  const isMarketingPageReducedHeader =
    pageScreen.subtypeValue === PAGE_TYPE_MARKETING;

  const isNativeAdvertising =
    pageScreen.__typename === NATIVE_ADVERTISING_CONTENT_TYPE;

  useImpressionTracking({
    trackingDetailImpression: pageScreen?.trackingDetailImpression,
    pathname: location.pathname,
    screenReady,
    isNativeAdvertising,
  });

  const origin =
    (isMarketingPage &&
      `${PAGE_SCREEN_MARKETING_TYPE}${
        (pageScreen.subtypeValue === ADVERTISING_TYPE_LONGFORM &&
          `-${ADVERTISING_TYPE_LONGFORM}`) ||
        ''
      }`) ||
    PAGE_SCREEN_DEFAULT;
  return (
    <div
      className={classNames({
        [styles.Wrapper]: isMarketingPage,
        [grid.GridCentered]: isMarketingPageReducedHeader,
        [styles.Longform]:
          pageScreen.subtypeValue === ADVERTISING_TYPE_LONGFORM,
      })}
      data-testid="page-container"
    >
      <EditButtons
        editContentUri={pageScreen.editContentUri}
        editRelationUri={pageScreen.editRelationUri}
        cloneContentUri={pageScreen.cloneContentUri}
      />

      {!isMarketingPage &&
        pageScreen.preferredUri &&
        pageScreen.activeMenuTrail && (
          <Breadcrumbs
            pageUrl={pageScreen.preferredUri}
            items={pageScreen.activeMenuTrail}
          />
        )}

      {!isMarketingPage && (
        <div data-testid="page-head-container" className={grid.Container}>
          <div className={grid.Row}>
            <div className={classNames(grid.GridCentered)}>
              <Head
                shortTitle={pageScreen.shortTitle}
                title={pageScreen.title}
                lead={pageScreen.lead}
              />
            </div>
          </div>
        </div>
      )}

      {pageScreen.subtypeValue === ADVERTISING_TYPE_LONGFORM &&
        pageScreen?.sponsor?.teaserImage?.image?.file?.relativeOriginPath && (
          <InView
            isInitialInView
            config={configIsVisible}
            reInitOnViewportLabelChange={false}
            reInitOnLocationChange={false}
          >
            {({ isInView }) => (
              <div className={styles.Placeholder}>
                <div
                  className={classNames(styles.SponsorBannerWrapper, {
                    [styles.Sticky]: !isInView,
                  })}
                >
                  <div
                    style={{
                      backgroundColor: pageScreen.sponsor?.colorCode || '',
                    }}
                  >
                    <div className={sections.Section}>
                      <div className={styles.Banner}>
                        <div className={grid.Container}>
                          <div className={styles.LinkWrapper}>
                            <Link
                              path={linkFromSponsor(pageScreen?.sponsor)}
                              rel="sponsored"
                              className={'sponsor-banner'}
                            >
                              <div className={styles.Label}>
                                {pageScreen.sponsor?.prefix === 'sponsored by'
                                  ? 'Präsentiert von'
                                  : pageScreen.sponsor?.prefix}
                              </div>
                              <Picture
                                relativeOrigin={
                                  pageScreen.sponsor.teaserImage.image.file
                                    .relativeOriginPath
                                }
                                focalPointX={
                                  pageScreen.sponsor.teaserImage.image.file
                                    ?.focalPointX
                                }
                                focalPointY={
                                  pageScreen.sponsor.teaserImage.image.file
                                    ?.focalPointY
                                }
                                style_320={STYLE_SCALEH_120}
                                className={styles.SponsorBannerLogo}
                                alt={
                                  pageScreen.sponsor.teaserImage.image.file
                                    ?.alt || ''
                                }
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView>
        )}

      <Paragraphs
        pageBody={pageScreen.body}
        origin={origin}
        hasContainer
        colStyle={classNames(
          grid.ColOffsetSm2,
          grid.ColSm20,
          grid.ColOffsetXl5,
          grid.ColXl14,
        )}
        isAdSuppressed={pageScreen?.channel?.suppressAds}
        isMarketingPageReducedHeader={isMarketingPageReducedHeader}
      />
    </div>
  );
};

export default compose(
  withHelmet({
    getNode: ({ pageScreen }: PageScreenPropsInner) => pageScreen,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
  }),
)(PageScreen);
