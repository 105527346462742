import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import pianoStateSelector from '../../../../../shared/selectors/pianoStateSelector';
import useInView, {
  UseInViewResponse,
} from '../../../../../shared/hooks/useInView';
import styles from './styles.legacy.css';
import {
  PianoTemplateParagraphComponent,
  PianoTemplateParagraphProps,
} from './typings';

const PianoTemplateParagraph: PianoTemplateParagraphComponent = ({
  pianoTemplateParagraph,
  colStyle,
}: PianoTemplateParagraphProps): ReactElement | null => {
  const initialAuthRequest = useSelector(
    (state) => pianoStateSelector(state).userMetadata.initialAuthRequest,
  );

  const { id, offerId, templateId, templateVariantId } = pianoTemplateParagraph;
  const pianoContainer = `piano-template-paragraph-${
    (id && id.replace(/[^a-zA-Z0-9-]/g, '')) || ''
  }`;

  const { setRef, isInView }: UseInViewResponse = useInView({
    rootMargin: '400px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (global?.tp && isInView && initialAuthRequest) {
      global.tp.push([
        'init',
        function () {
          global.tp.offer.show({
            templateId,
            offerId,
            templateVariantId,
            displayMode: 'inline',
            containerSelector: `#${pianoContainer}`,
          });
        },
      ]);
    }
  }, [
    initialAuthRequest,
    isInView,
    templateId,
    offerId,
    templateVariantId,
    pianoContainer,
  ]);

  if (!id || !offerId) {
    return null;
  }

  return (
    <div
      className={classNames(
        'piano-container',
        styles[`${templateId}_${templateVariantId}`],
        { [colStyle]: !!colStyle },
      )}
      ref={setRef}
      id={pianoContainer}
    ></div>
  );
};

export default PianoTemplateParagraph;
