import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import withHandlers from 'recompose/withHandlers';
import { replaceTrackingUrlPlaceholders } from '../helpers/tracking';
import namedComponent from './namedComponent';

// ---------------------------------------------------------------------------------- //
// COMPOSE
// ---------------------------------------------------------------------------------- //

const track = ({ node }) => {
  if (node && node.trackingTeaserImpression) {
    fetch(replaceTrackingUrlPlaceholders(node.trackingTeaserImpression), {
      mode: 'no-cors',
    });
  }
};

const doTrack = (props, propName) => {
  if (!propName) {
    return props;
  }

  const teaserPath = propName.split('.');

  const nodes = teaserPath.reduce(
    (acc, value) => (acc && acc[value]) || null,
    props,
  );

  if (nodes && Array.isArray(nodes)) {
    nodes.forEach(track);
  }
};

const withMapProps = (propName) =>
  lifecycle({
    componentDidMount() {
      if (this.props.locationState?.screenReady === true) {
        doTrack(this.props, propName);
      }

      return this.props;
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.locationState?.screenReady !==
          prevProps.locationState?.screenReady &&
        this.props.locationState?.screenReady === true
      ) {
        doTrack(this.props, propName);
      }
    },
  });

const extendWithHandlers = withHandlers({
  trackImpression: (props) => (propName) => doTrack(props, propName),
});

export const mapStateToProps = (options) => (state) => ({
  locationState: options.locationStateSelector(state),
});

export default (options) =>
  ({ propName }) =>
  (Component) =>
    (__CLIENT__ &&
      compose(
        connect(mapStateToProps(options)),
        extendWithHandlers,
        namedComponent('withImpressionTrack'),
        withMapProps(propName),
      )(Component)) ||
    Component;
