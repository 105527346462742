import React, { Component, ReactElement } from 'react';
import { compose } from 'recompose';
import { generateMetaLinks } from '../../../../../shared/helpers/withHelmet';
import withHelmet from '../../../../shared/decorators/withHelmet';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import Breadcrumbs from '../../components/Breadcrumbs';
import EditButtons from '../../components/EditButtons';
import OverviewPage from '../../components/OverviewPage';
import PartnerBanner from '../../components/PartnerBanner';
import StatusPage from './../StatusPage';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { OVERVIEW_PAGE_SIZE } from '../../components/TeaserGrid/constants';
import { SITE_TITLE } from '../../constants';
import { ChannelProps } from './typings';

type ChannelPropsInner = ChannelProps;

const getFallbackTitle = (channel: Channel) =>
  `${
    channel?.landingPage?.metaTitle || channel?.settings?.title || ''
  } - ${SITE_TITLE}`;

const getFallbackHelmetNode = ({ channel, location, page }) => {
  const numberCount: number = channel?.entities?.count || 0;
  const totalOfPages: number = Math.ceil(numberCount / OVERVIEW_PAGE_SIZE);
  const metaLink: Array<MetaLink> = generateMetaLinks(
    location,
    null,
    page,
    totalOfPages,
  );
  const fallbackTitle = getFallbackTitle(channel);
  const description = channel?.settings?.lead || '';

  return {
    seoTitle: fallbackTitle,
    metaDescription: description,
    heroImageBody: [
      {
        image: {
          file: {
            relativeOriginPath:
              channel?.settings?.headerImage?.file?.relativeOriginPath,
          },
        },
      },
    ],
    metaLink,
    preferredUri: location.pathname,
  };
};

class ChannelComponent extends Component<
  ChannelPropsInner & Pick<RouterProps, 'location'>
> {
  render(): ReactElement {
    const { channel, location, page } = this.props;

    if (
      !channel ||
      !channel?.entities ||
      !Array.isArray(channel?.entities?.edges) ||
      !channel?.entities?.edges.length
    ) {
      return <StatusPage />;
    }

    return (
      <TestFragment data-testid="channel-container">
        <EditButtons editContentUri={channel.editContentUri} />

        {channel.preferredUri && channel.activeMenuTrail && (
          <TestFragment data-testid="channel-breadcrumb-wrapper">
            <Breadcrumbs
              pageUrl={channel.preferredUri}
              items={channel.activeMenuTrail}
            />
          </TestFragment>
        )}

        {channel?.settings?.channel?.sponsors?.edges &&
          Array.isArray(channel?.settings?.channel?.sponsors?.edges) && (
            <TestFragment data-testid="channel-partnerbanner-wrapper">
              <PartnerBanner
                sponsors={channel?.settings?.channel?.sponsors?.edges || []}
              />
            </TestFragment>
          )}

        <OverviewPage location={location} page={page} routeObject={channel} />
      </TestFragment>
    );
  }
}

export default compose(
  withHelmet({
    getNode: (mapProps): LandingPage =>
      mapProps.channel?.landingPage || getFallbackHelmetNode(mapProps),
    getFallbackTitle: (mapProps: ChannelPropsInner): string =>
      getFallbackTitle(mapProps.channel),
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps) => mapProps?.landingPage?.grid?.edges || [],
  }),
)(ChannelComponent);
