import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import locationStateSelector from '../../../../shared/selectors/locationStateSelector';
import LoadingBar from './components/LoadingBar';
import { useSSRContext } from '../../../../../common/components/SSRContext';

const Loading = () => {
  const { isSSR } = useSSRContext();
  const isRefetchingData = useSelector(
    (state) => locationStateSelector(state).isRefetchingData,
  );
  const screenReady = useSelector(
    (state) => locationStateSelector(state).screenReady,
  );
  return !isSSR && (!screenReady || isRefetchingData) ? <LoadingBar /> : null;
};

export default memo(Loading);
