/* istanbul ignore file */

import iconFactory from '../../../../../common/components/Icon/factory';
import iconFont from '../../assets/styles/raschFont.legacy.css';

const Icon = iconFactory({
  iconFont,
});

export default Icon;
