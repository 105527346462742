/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { GET_AUTHORS_PAGE } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location }) => {
    const path = location?.pathname?.substr(1);
    return {
      query: GET_AUTHORS_PAGE,
      variables: {
        path,
      },
    };
  },
};
