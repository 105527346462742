import {
  HEROSLIDER_HOME,
  HEROSLIDER_HOME_BODY_HEALTH,
  HEROSLIDER_HOME_FAMILY,
  HEROSLIDER_HOME_PEOPLE,
  HEROSLIDER_HOME_STYLE,
} from '../../HeroSlider/constants';

/**
 * SI specific types for grid config
 */
export const TYPE_HEROSLIDER_HOME = HEROSLIDER_HOME;
export const TYPE_HEROSLIDER_HOME_PEOPLE = HEROSLIDER_HOME_PEOPLE;
export const TYPE_HEROSLIDER_HOME_STYLE = HEROSLIDER_HOME_STYLE;
export const TYPE_HEROSLIDER_HOME_FAMILY = HEROSLIDER_HOME_FAMILY;
export const TYPE_HEROSLIDER_HOME_BODY_HEALTH = HEROSLIDER_HOME_BODY_HEALTH;
export const TYPE_DIVIDER = 'grid-divider';

/**
 * SI specific grid config layouts
 */
export const GRID_LAYOUT_HOME_TOP = 'homeTop';
export const GRID_LAYOUT_HOME_BOTTOM = 'homeBottom';
export const GRID_LAYOUT_PEOPLE_TOP = 'homePeopleTop';
export const GRID_LAYOUT_STYLE_TOP = 'homeStyleTop';
export const GRID_LAYOUT_STYLE_BOTTOM = 'homeStyleBottom';
export const GRID_LAYOUT_FAMILY_TOP = 'homeFamilyTop';
export const GRID_LAYOUT_BODY_AND_HEALTH_TOP = 'homeBodyAndHealthTop';
export const GRID_LAYOUT_BODY_AND_HEALTH_FAMILY_BOTTOM =
  'homeBodyAndHealthFamilyBottom';
export const GRID_LAYOUT_LANDSCAPE_SPECIAL = 'landscapeSpecial';
export const GRID_LAYOUT_HOROSCOPE_4X3 = 'horoscope4x3';
export const GRID_LAYOUT_MENU_OVERLAY = 'menuOverlay';
export const GRID_LAYOUT_VIDEO_BLOGS = 'videoBlogs';
export const GRID_LAYOUT_BLOGS = 'blogs';
export const GRID_LAYOUT_LANDINGPAGE_SPECIAL_4X3 = 'landingPageSpecial4x3';
export const GRID_LAYOUT_TEASER_BLOG_POST_MEDIUM_4X4 =
  'teaserBlogPostMedium4x4';
export const GRID_LAYOUT_TEASER_PORTRAIT_4X4 = 'teaserPortrait4x4';
export const GRID_LAYOUT_TEASER_KEYWORD = 'keyword';
export const GRID_LAYOUT_TEASER_3X4 = 'teaser3x4';
