import React from 'react';
import Link from '../../common/components/Link';

const renderTopic = (item: TopicEdge, index: number, style: any) => {
  if (item?.node) {
    return (
      <span key={`navigation-link-${item.node.id}`}>
        {index ? ', ' : ''}
        <Link
          path={item.node.preferredUri}
          label={item.node.title}
          className={style}
        />
      </span>
    );
  }
};

export default renderTopic;
