/**
 * @file    Parallax image paragraph
 * @author  Stephan Schmid (stephan.schmid@ringieraxelspringer.ch)
 * @author  Serkan Ucmak (serkan.ucmak@ringieraxelspringer.ch)
 * @date    2019-02-13
 */

import parallaxImageParagraphFactory from '../../../../../../../common/components/Paragraphs/components/ParallaxImageParagraph/factory';
import styles from './styles.legacy.css';

export default parallaxImageParagraphFactory({
  styles: {
    CreditsTitle: styles.CreditsTitle,
    ImageDefault: styles.ImageDefault,
    Parallax: styles.Parallax,
  },
});
