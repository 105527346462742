import React, { Component, ReactElement } from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../../../common/components/Link';
import Icon from '../../../../../Icon';
import Img from '../../../../../Img';
import { ROUTE_PUZZLES } from '../../../../../../constants';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import helpers from '../../../../../../assets/styles/helpers.legacy.css';
import styles from './styles.legacy.css';
import puzzlesLogo from './../../../../../../assets/graphics/puzzles/puzzles-logo-white.svg';
import { MenuHeaderProps } from './typings';

export type MenuHeaderPropsInner = MenuHeaderProps;

class PuzzlesMenuHeader extends Component<MenuHeaderPropsInner> {
  render(): ReactElement | null {
    const { menuCloseHandler } = this.props;

    if (!menuCloseHandler) {
      return null;
    }
    return (
      <div className={styles.Wrapper}>
        <div className={grid.Container}>
          <div className={helpers.PullOutSm}>
            <div className={styles.Header}>
              <div className={styles.Logo} data-testid="logo-wrapper">
                <Link path={`/${ROUTE_PUZZLES}`} onClick={menuCloseHandler}>
                  <div className={styles.LogoWrapper}>
                    <Img
                      addClass={styles.Logo}
                      alt="Logo Rätselecke"
                      url={puzzlesLogo}
                    />
                  </div>
                </Link>
              </div>
              <div className={styles.Content}></div>
              <div className={styles.IconWrapper}>
                <button
                  onClick={menuCloseHandler}
                  className={classNames('track-menu', styles.MenuButton)}
                  data-track-action="close"
                  data-track-element="menu"
                  aria-label="Menu schliessen"
                >
                  <Icon type={'IconCloseButtonSimple'} addClass={styles.Icon} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PuzzlesMenuHeader;
