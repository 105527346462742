import {
  TEASER_LAYOUT_BLOG_POST_MEDIUM,
  TEASER_LAYOUT_CHANNEL,
} from '../../../../../shared/constants/teaser';
import { TYPE_TEASER } from '../constants';

export const blogs = {
  gridGroups: [
    {
      config: {
        hasContainer: true,
      },
      items: [
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_CHANNEL,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
          downloadPriority: 'high',
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_CHANNEL,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_CHANNEL,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_CHANNEL,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_BLOG_POST_MEDIUM,
        },
      ],
    },
  ],
};
