/* istanbul ignore file */

import ministageVideoFactory from '../../../../../../../../../common/components/Paragraphs/components/MinistageParagraph/components/MinistageVideo/factory';
import VideoStage from '../../../../../VideoStage';

export default ministageVideoFactory({
  VideoStage,
  styles: {
    Wrapper: '',
    InnerWrapper: '',
  },
});
