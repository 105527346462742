import { compose } from 'redux';
import topicFactory from '../../../../../common/screens/Topic/factory';
import { ensureTeaserInterface } from '../Teaser/shared/helpers';
import withHelmet from '../../../../shared/decorators/withHelmet';
import Breadcrumbs from '../../components/Breadcrumbs';
import TeaserGrid from '../TeaserGrid';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../../components/Pager';
import { PAGE_SIZE } from './constants.js';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { GRID_LAYOUT_TEASER_KEYWORD } from '../TeaserGrid/gridConfigs/constants';
import styles from './styles.legacy.css';

const TopicPage = topicFactory({
  gridConfig: GRID_LAYOUT_TEASER_KEYWORD,
  ensureTeaserInterface,
  Breadcrumbs,
  TeaserGrid,
  Pager: Pager,
  pagerType: PAGER_TYPE_PAGE_LOADER,
  styles,
});

export default compose(
  withHelmet({
    getNode: (mapProps) => mapProps.topic,
    getImage: (mapProps) => mapProps.topic?.heroImageBody?.[0]?.image?.file,
    getNodesCount: (mapProps): number => mapProps.topic?.entities?.count || 0,
    pageSize: PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps) => mapProps.topic?.entities?.edges,
  }),
)(TopicPage);
