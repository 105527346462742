import React, { ReactElement, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle, withPropsOnChange, withState } from 'recompose';
import { WatchQueryFetchPolicy, useQuery } from '@apollo/client';
import ensureCommentsInterface from '../../../shared/helpers/ensureCommentsInterface';
import { hashString, noop } from '../../../shared/helpers/utils';
import authStateSelector from '../../../shared/selectors/authStateSelector';
import withComments, {
  WithComments,
} from '../../../shared/decorators/withComments';
import TestFragment from '../../../shared/tests/components/TestFragment';
import { dispatchHybridAppEvent } from '../HybridAppProvider';
import {
  COMMENT_SECTION_ID,
  COMMENT_SIZE,
  COMMENT_STATUS_OPEN,
} from '../../../shared/constants/comments';
import { GLOBAL_SEARCH_SORT_DESC } from '../../../shared/constants/globalSearch';
import { CommentProps } from '../Comments/components/Comment/typings';
import {
  CommentsComponent,
  CommentsFactoryOptions,
  CommentsProps,
  CommentsQueryComponentProps,
} from './typings';

export type CommentsPropsInner = CommentsProps &
  WithComments & {
    isClientSideSorted: boolean;
    setClientSideSorted: (isClientSideSorted: boolean) => void;
    parentCommentsCount: number;
    hasMoreCommentsThanVisible: boolean;
    isDescending: boolean;
    currentCommentsPaging: number;
    setCurrentCommentsPaging: Function;
    setCommentsCount: Function;
    isAuthenticated: boolean;
  };

type CommentsListProps = {
  comments: Array<CommentGraphListItem>;
  reverseClientSide: boolean;
  commentStatus: string;
  articleId: string;
  gcid: string;
  commentsData: CommentsQueryComponentProps;
};

const CommentsFactory = ({
  styles,
  grid,
  Icon,
  Comment,
  Commenting,
  CommentSort,
  Pager,
  pagerType,
  setCommentsCountAction,
  GET_COMMENTS,
  pager: getPagerJsx = noop,
}: CommentsFactoryOptions): CommentsComponent => {
  const CommentsList = ({
    comments,
    reverseClientSide = false,
    commentStatus,
    articleId,
    gcid,
    commentsData,
  }: CommentsListProps): ReactElement => {
    // create a deep copy to not affect the original references by revert the order
    const commentsCopy: Array<CommentGraphListItem> = !reverseClientSide
      ? comments
      : comments.map(
          (item: CommentGraphListItem): CommentGraphListItem =>
            Object.assign({}, item),
        );

    const commentList: Array<ReactElement> = ensureCommentsInterface(
      commentsCopy,
    ).map(
      ({
        id,
        name,
        createDate,
        body,
        commentReplies,
      }: CommentProps): ReactElement => (
        <Comment
          key={`comment-${id}-${name}-${hashString(body)}`}
          id={id}
          articleId={articleId}
          gcid={gcid}
          name={name}
          createDate={createDate}
          body={body}
          commentReplies={commentReplies}
          commentStatus={commentStatus}
          commentsData={commentsData}
        />
      ),
    );

    if (reverseClientSide) {
      return <>{commentList.reverse()}</>;
    }
    return <>{commentList}</>;
  };

  const Comments = ({
    articleId,
    gcid,
    commentStatus,
    toggleCommentsSortOrder,
    isClientSideSorted,
    setClientSideSorted,
    isDescending,
    hasMoreCommentsThanVisible,
    currentCommentsPaging,
    setCurrentCommentsPaging,
    commentsSortOrder,
    setCommentsCount,
    isAuthenticated,
    isInView = true,
  }: CommentsPropsInner): ReactElement | Array<ReactElement> => {
    const gqlVariables = {
      limit: currentCommentsPaging * COMMENT_SIZE,
      id: articleId,
      offset: 0,
      sort: commentsSortOrder,
    };
    const commentsRef = useRef({ commentStatus, totalCount: 0 });

    // https://www.apollographql.com/docs/react/api/react-apollo/#optionsfetchpolicy
    const fetchPolicy: WatchQueryFetchPolicy = isAuthenticated
      ? 'network-only'
      : 'cache-first';

    const { data, loading } = useQuery<CommentsQueryComponentProps>(
      GET_COMMENTS,
      {
        variables: gqlVariables,
        fetchPolicy,
      },
    );

    useEffect(() => {
      setCommentsCount(data?.commentsById?.totalCount || 0);
      commentsRef.current = {
        commentStatus,
        totalCount: data?.commentsById?.totalCount || 0,
      };
    }, [data?.commentsById?.totalCount, setCommentsCount, commentStatus]);

    useEffect(() => {
      if (isInView && data) {
        dispatchHybridAppEvent('get-comments-data', commentsRef.current);
      }
    }, [isInView, data]);

    if (loading && currentCommentsPaging < 1) {
      return null;
    }

    const edges: Array<CommentGraphListItem> | null =
      data?.commentsById?.edges || null;
    const commentCountWithReplies: number = data?.commentsById?.totalCount || 0;
    const commentCountWithoutReplies: number = data?.commentsById?.count || 0;
    const hasComments = !!commentCountWithReplies;

    if (commentStatus !== COMMENT_STATUS_OPEN && !hasComments) {
      return null;
    }

    const pager = getPagerJsx({
      currentPage: currentCommentsPaging,
      updatePage: setCurrentCommentsPaging,
      itemsCount: commentCountWithoutReplies,
      itemsPerPage: COMMENT_SIZE,
      isLoading: loading,
      text: 'Mehr Kommentare anzeigen',
    });

    const pagerJsx: ReactElement = pager || (
      <Pager
        className={styles.Pager}
        component={pagerType}
        currentPage={currentCommentsPaging}
        updatePage={setCurrentCommentsPaging}
        itemsCount={commentCountWithoutReplies}
        itemsPerPage={COMMENT_SIZE}
      >
        <span>Mehr Kommentare anzeigen</span>
        <Icon addClass={styles.Icon} type="IconChevronDown" />
      </Pager>
    );

    return (
      <div
        className={styles.Inner}
        id={COMMENT_SECTION_ID}
        data-testid="comments-wrapper"
      >
        <div className={styles.Container}>
          <div className={grid.Row}>
            <div className={styles.Column}>
              {commentStatus === COMMENT_STATUS_OPEN && (
                <TestFragment data-testid="comments-commenting-wrapper">
                  <Commenting
                    articleId={articleId}
                    gcid={gcid}
                    commentsData={data}
                  />
                </TestFragment>
              )}
              {hasComments && (
                <TestFragment data-testid="comments-comments-wrapper">
                  <TestFragment data-testid="comments-title-wrapper">
                    <h3 className={styles.Title}>
                      <span className={styles.Counter}>
                        {commentCountWithReplies}
                      </span>{' '}
                      {`Kommentar${commentCountWithReplies !== 1 ? 'e' : ''}`}
                    </h3>
                  </TestFragment>
                  <CommentSort
                    isDescending={isDescending}
                    toggleSortOrder={toggleCommentsSortOrder}
                    isReverseClientSide={!hasMoreCommentsThanVisible}
                    isClientSideSorted={isClientSideSorted}
                    setClientSideSorted={setClientSideSorted}
                  />
                  <CommentsList
                    comments={edges}
                    reverseClientSide={isClientSideSorted}
                    commentStatus={commentStatus}
                    articleId={articleId}
                    gcid={gcid}
                    commentsData={data}
                  />
                  {pagerJsx}
                </TestFragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const updateHasMoreCommentsThanVisible = withPropsOnChange(
    ['parentCommentsCount', 'currentCommentsPaging'],
    ({
      parentCommentsCount,
      currentCommentsPaging,
    }: CommentsPropsInner): Record<string, any> => ({
      hasMoreCommentsThanVisible:
        parentCommentsCount > COMMENT_SIZE * currentCommentsPaging,
    }),
  );

  const withSortOrderChanged = withPropsOnChange(
    ['commentsSortOrder'],
    ({ commentsSortOrder }: CommentsPropsInner): Record<string, any> => ({
      isDescending: commentsSortOrder === GLOBAL_SEARCH_SORT_DESC,
    }),
  );

  const withLifecycle = lifecycle({
    componentWillUnmount(): void {
      this.props.setCommentsCount(0);
    },
  });

  const mapDispatchToProps: Record<string, any> = {
    setCommentsCount: setCommentsCountAction,
  };

  const mapStateToProps = (state) => ({
    isAuthenticated: authStateSelector(state).isAuthenticated,
  });

  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withComments,
    withSortOrderChanged,
    withState<Record<string, any>, Record<string, any>, number>(
      'currentCommentsPaging',
      'setCurrentCommentsPaging',
      1,
    ),
    withState<Record<string, any>, Record<string, any>, boolean>(
      'isClientSideSorted',
      'setClientSideSorted',
      false,
    ),
    updateHasMoreCommentsThanVisible,
    withLifecycle,
  )(Comments);
};

export default CommentsFactory;
