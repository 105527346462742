import React from 'react';
import classNames from 'classnames';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import {
  AdvantagesParagraphFactoryOptions,
  AdvantagesParagraphProps,
} from './typings';

const AdvantagesParagraphFactory = ({
  AdvantagesItem,
  styles,
}: AdvantagesParagraphFactoryOptions) => {
  const AdvantagesParagraph = (props: AdvantagesParagraphProps) => {
    const { header, advantagesItems } = props.entry;

    if (
      !advantagesItems ||
      !Array.isArray(advantagesItems) ||
      advantagesItems.length === 0
    ) {
      return null;
    }
    return (
      <div className={styles.OuterWrapper}>
        <div data-testid="wrapper" className={styles.Wrapper}>
          {header && (
            <TestFragment data-testid="title">
              <h3 className={styles.Title}>{header}</h3>
            </TestFragment>
          )}

          {advantagesItems.map((item, index) => {
            const itemWrapper =
              (typeof styles.ItemWrapper === 'function' &&
                styles.ItemWrapper(advantagesItems.length, index)) ||
              (typeof styles.ItemWrapper === 'string' && styles.ItemWrapper);

            return (
              <div
                key={`item-${item.id}`}
                className={classNames(itemWrapper)}
                data-testid="item-wrapper"
              >
                <AdvantagesItem
                  item={item}
                  isWide={advantagesItems.length === 4}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return AdvantagesParagraph;
};
export default AdvantagesParagraphFactory;
