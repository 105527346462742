import {
  TYPE_AD,
  TYPE_TEASER,
} from '../../../../../../../common/components/TeaserGrid/gridConfigs/constants';
import { MHPA_2 } from '../../../../../../../shared/constants/adZone';
import {
  TEASER_LAYOUT_L,
  TEASER_LAYOUT_S,
} from '../../../../../../../shared/constants/teaser';
import { WIDEBOARD_2 } from '../../../AppNexus/constants';

export const keyword = {
  gridGroups: [
    {
      config: {
        hasContainer: true,
      },
      items: [
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_L,
          downloadPriority: 'high',
        },
        {
          type: TYPE_AD,
          adConfig: [{ slot: MHPA_2, deviceType: 'mobile' }],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_L,
          downloadPriority: 'high',
        },
        {
          type: TYPE_AD,
          adConfig: [
            {
              slot: WIDEBOARD_2,
              deviceType: 'tabletDesktop',
              isMultiPlacement: false,
            },
          ],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_L,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_L,
        },
        {
          type: TYPE_AD,
          adConfig: [
            { slot: MHPA_2, deviceType: 'mobile', isMultiPlacement: false },
          ],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_AD,
          adConfig: [
            {
              slot: WIDEBOARD_2,
              deviceType: 'tabletDesktop',
              isMultiPlacement: false,
            },
          ],
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_L,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_L,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
        {
          type: TYPE_TEASER,
          teaserType: TEASER_LAYOUT_S,
        },
      ],
    },
  ],
};
