import React, { ReactElement } from 'react';
import compose from 'recompose/compose';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import withPagePager from '../../../../../shared/decorators/withPagePager';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withHelmet from '../../../../shared/decorators/withHelmet';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import Breadcrumbs from '../../components/Breadcrumbs';
import OverviewPage from '../../components/OverviewPage';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { GRID_LAYOUT_BLOGS } from '../../components/TeaserGrid/gridConfigs/constants';
import { SITE_TITLE } from '../../constants';
import { PAGE_SIZE } from './constants';
import { BlogsProps } from './typings';

type BlogsPropsInner = BlogsProps & {
  data: QueryRoot & {
    loading: boolean;
    environment: Route & {
      routeByPath: Route;
      termsByVocabulary: Channel;
    };
  };
};

const getFallbackTitle = (landingPage: LandingPage) =>
  `${landingPage?.title || 'Blogs'} - ${SITE_TITLE}`;

const Blogs = ({ data, location, page }: BlogsPropsInner): ReactElement => {
  if (
    !data ||
    !data.environment ||
    !data.environment.termsByVocabulary ||
    !data.environment.routeByPath ||
    !data.environment.routeByPath.object
  ) {
    return null;
  }

  const blogs: ChannelConnection = data.environment.termsByVocabulary;
  const landingPage: LandingPage = data.environment.routeByPath.object;

  const termSettings: TermSettings = {
    title: landingPage.title || 'Blogs',
    lead: landingPage.lead,
  };

  const updatedData: {
    routeObject: Channel;
  } = (blogs.edges || []).reduce(
    (updatedData, blog: ChannelEdge) => {
      const edges = updatedData.routeObject.entities.edges;

      const blogNode = blog.node || null;

      if (blogNode && blogNode.showOnBlogsOverview) {
        // add TeaserChannel at first place in row
        edges.push({ node: blogNode });

        // we start with placeholders to keep grid: 1 channel teaser, then three teasers with articles
        const blogArticles = [null, null, null];

        if (blogNode.entities?.items) {
          blogNode.entities.items.forEach(
            (blogPost, index) => (blogArticles[index] = blogPost),
          );
        }

        // if blog has blog posts add them to gridConfig
        blogArticles.forEach((blogPost: Article) => {
          edges.push({ node: blogPost });
        });
      }

      return updatedData;
    },
    {
      routeObject: {
        entities: {
          edges: [],
        },
      },
    },
  );
  updatedData.routeObject.entities.count =
    data.environment.termsByVocabulary.count;
  return (
    <TestFragment data-testid="blogs-container">
      {landingPage.preferredUri && landingPage.activeMenuTrail && (
        <Breadcrumbs
          pageUrl={landingPage.preferredUri}
          items={landingPage.activeMenuTrail}
        />
      )}

      <OverviewPage
        location={location}
        routeObject={updatedData.routeObject}
        termSettings={termSettings}
        gridConfig={GRID_LAYOUT_BLOGS}
        paragraphType="BlogsScreen"
        page={page}
        pageSize={PAGE_SIZE}
      />
    </TestFragment>
  );
};

export default compose(
  withPagePager,
  withHelmet({
    getNode: (mapProps: BlogsPropsInner): RouteObjectInterface | null =>
      mapProps.data?.environment?.routeByPath?.object || null,
    getFallbackTitle: (mapProps: BlogsPropsInner): string =>
      getFallbackTitle(mapProps.data?.environment?.routeByPath?.object),
    getNodesCount: (mapProps): number =>
      mapProps.data?.environment?.routeByPath?.object.entities?.count || 0,
    pageSize: () => PAGE_SIZE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps) =>
      mapProps?.data?.environment?.routeObject?.entities?.edges || [],
  }),
  withAppNexus({
    parseTrackingData,
  }),
)(Blogs);
