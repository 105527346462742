import { connect } from 'react-redux';
import compose from 'recompose/compose';
import lifecycle from 'recompose/lifecycle';
import namedComponent from './namedComponent';

// ---------------------------------------------------------------------------------- //
// HOC
// ---------------------------------------------------------------------------------- //

type Config = {
  hash?: string;
  offset?: number;
};

const defaultConfig: Config = {
  hash: 'page',
  offset: 50,
};

const scrollToAnchor = (config: Config) => (scope) => {
  if (
    !scope.props.locationState.screenReady ||
    !scope.props.locationState.locationBeforeTransitions ||
    !scope.props.locationState.locationBeforeTransitions.hash ||
    scope.props.locationState.locationBeforeTransitions.hash !==
      `#${config.hash}`
  ) {
    return;
  }

  const el = document.getElementById(config.hash);

  if (!el) {
    return;
  }

  const boundingClientRect = el.getBoundingClientRect();

  if (!boundingClientRect || !boundingClientRect.top) {
    return;
  }

  const top =
    (window.pageYOffset || document.documentElement?.scrollTop || 0) -
    (document.documentElement?.clientTop || 0);

  const newPosition = top + boundingClientRect.top - config.offset;

  if (newPosition > 0) {
    global.scrollTo(0, newPosition);
  }
};

export const mapStateToProps = (factoryOptions) => (state) => ({
  locationState: factoryOptions.selectLocationState(state),
});

export const withStoreConnection = (factoryOptions) =>
  connect(mapStateToProps(factoryOptions));

export const withLifecycle = (config: Config) =>
  lifecycle({
    componentDidMount() {
      scrollToAnchor(config)(this);
    },
    componentDidUpdate() {
      scrollToAnchor(config)(this);
    },
  });

export default (factoryOptions) =>
  (config: Config = defaultConfig) =>
  (Component) =>
    (__CLIENT__ &&
      compose(
        namedComponent('withScrollToAnchor'),
        withStoreConnection(factoryOptions),
        withLifecycle({ ...defaultConfig, ...config }),
      )(Component)) ||
    Component;
