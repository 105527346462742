import { Component } from 'react';
import scriptLoader from 'react-async-script-loader';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import { safariVersion } from '../../../shared/helpers/deviceDetector';
import { getRCTrackingSource } from '../../../shared/helpers/getRCTrackingSource';
import handleWysiwygLink from '../../../shared/helpers/handleWysiwygLink';
import storageAvailable from '../../../shared/helpers/storage';
import { tealiumTrackEvent } from '../../../shared/helpers/tealium';
import { getCookieByName, log, setCookie } from '../../../shared/helpers/utils';
import locationStateSelector from '../../../shared/selectors/locationStateSelector';
import pianoStateSelector from '../../../shared/selectors/pianoStateSelector';
import withNavigate, {
  WithNavigateProps,
} from '../../../shared/decorators/withNavigate';
import {
  SetPianoAccesGranted,
  SetPianoBrowserMetadata,
  SetPianoWebinarAccesGranted,
  setPianoAccesGranted,
  setPianoBrowserMetadata,
  setPianoWebinarAccesGranted,
} from '../../../shared/actions/piano';
import { Auth0 } from '../Auth0Provider';
import { dispatchHybridAppEvent } from '../HybridAppProvider';
import {
  RESTRICTION_STATUS_PAID,
  RESTRICTION_STATUS_REGISTERED,
} from '../../../shared/constants/content';
import {
  ONELOG_INVITATIONS_EMAIL_CANNOT_BE_VERIFIED,
  ONELOG_INVITATIONS_INVITATION_SENT,
  ONELOG_INVITATIONS_USER_ALREADY_EXISTS,
  ONELOG_INVITATIONS_USER_EXISTS_OR_INVITATION_SENT,
} from '../../../shared/constants/onelog';
import {
  PIANO_ALREADY_HAVE_ACCESS_EXPERIENCES,
  PIANO_CHECKOUT_CSS_CLASS,
  PIANO_CONTAINER_ANIMATED,
  PIANO_CONTAINER_ARTICLE_ASIDE,
  PIANO_CONTAINER_LANDING_ASIDE,
  PIANO_CONTAINER_LOCKED,
  PIANO_CONTAINER_METERING,
  PIANO_CONTAINER_SLIDE_DOWN_ANIMATED,
  PIANO_EVENT_CHECKOUT_START,
  PIANO_LOCAL_STORAGE_PARAMS,
  PIANO_PLACEHOLDER_ASIDE,
  PIANO_PLACEHOLDER_INLINED,
} from '../../../shared/constants/piano';
import { AUTH0_LOGIN_CASE_EMAIL_ONLY } from '../Auth0Provider/constants';
import { ZINDEXES } from '../../assets/styles/variablesDefault.legacy.css';
import { LoginCase } from '../Auth0Provider/typings';

type PianoPropsInner = WithNavigateProps & {
  isScriptLoaded: boolean;
  isScriptLoadSucceed: boolean;
  pageMetadata: PianoPageMetadata;
  userMetadata: PianoUserMetadata;
  setPianoBrowserMetadata: SetPianoBrowserMetadata;
  setPianoAccesGranted: SetPianoAccesGranted;
  setPianoWebinarAccesGranted: SetPianoWebinarAccesGranted;
  browserMetadata: PianoBrowserMetadata;
  screenReady: boolean;
  isCrawler: boolean;
  isPrintArticle: boolean;
  isHybridApp: boolean;
  messages?: PianoMessages;
};

type UserNotificationsSettings = {
  isPushNotificationsSupported: boolean;
  isPushNotificationsEnabled: boolean;
  notificationsPermission: 'default' | 'granted' | 'denied';
};

const pianoZIndex = parseInt(ZINDEXES.zIndexPianoOverlay, 10);
const pianoModalZIndex = pianoZIndex + 5;

export class Piano extends Component<PianoPropsInner> {
  checkoutState = false;
  isInitialized = false;
  currentProps = null;
  astFilePath = '';
  isAdblockerActive = undefined;
  fallbackMessages = {
    invitationCommonError:
      'Momentan gibt es ein tehnisches Problem. Bitte versuchen Sie es später noch einmal.',
    invitationUnverifiedEmail:
      'Nutzer E-Mail Adresse kann nicht verifiziert werden.',
  };
  pianoConversionParams = null;

  constructor(props: PianoPropsInner) {
    super(props);
  }

  _adjustModalZIndex(event: string) {
    // set z-indexes to defined values
    const elements = document.getElementsByClassName(
      'tp-modal',
    ) as HTMLCollectionOf<HTMLElement>;

    if (event === PIANO_EVENT_CHECKOUT_START) {
      [...elements].forEach((element) => {
        element.style.zIndex = '' + (pianoModalZIndex + 20);
      });
    }
  }

  _hideSkeletonForInlinePaywall(conversion: Record<string, any>) {
    const paywallDisplayMode: string = conversion.displayMode;
    const paywallSelectorTag: HTMLElement = document.querySelector(
      conversion.containerSelector,
    );
    const iframeElement: HTMLCollectionOf<HTMLIFrameElement> =
      paywallSelectorTag.getElementsByTagName('iframe');

    const iframeLoadingListener = function (className: string) {
      iframeElement[0].addEventListener('load', () => {
        paywallSelectorTag.classList.remove(className);
      });
    };

    if (
      [...paywallSelectorTag.classList].some((className: string) =>
        className.includes(PIANO_PLACEHOLDER_ASIDE),
      )
    ) {
      iframeLoadingListener(PIANO_PLACEHOLDER_ASIDE);
    }

    if (paywallDisplayMode === 'inline') {
      iframeLoadingListener(PIANO_PLACEHOLDER_INLINED);
    }
  }

  _toggleCheckoutClass(isCheckout = true) {
    const element: HTMLElement | null =
      document.querySelector('.tp-iframe-wrapper');
    if (element && isCheckout) {
      element.classList.add(PIANO_CHECKOUT_CSS_CLASS);
      const modal: HTMLElement | null = document.querySelector('.tp-modal');
      if (modal) {
        modal.scrollTop = 0;
      }
    } else if (element && !isCheckout) {
      element.classList.remove(PIANO_CHECKOUT_CSS_CLASS);
    }
  }

  async _getBrowserMetadata() {
    const isIncognitoMode = await this._detectIncognitoMode();
    const userNotificationsSettings =
      await this._getUserNotificationsSettings();

    this.props.setPianoBrowserMetadata({
      browserMode: isIncognitoMode ? 'incognito' : 'normal',
      isPushNotificationsSupported:
        userNotificationsSettings.isPushNotificationsSupported,
      isPushNotificationsEnabled:
        userNotificationsSettings.isPushNotificationsEnabled,
      notificationsPermission:
        userNotificationsSettings.notificationsPermission,
    });
  }

  async _logSovendusConversion(conversion: Record<string, any>) {
    const sovReqTokenCookie = getCookieByName('sovReqToken');

    // if sovReqToken cookie exist and is paid term
    // call /track-piano-conversion
    if (
      conversion.chargeAmount &&
      conversion.chargeAmount > 0 &&
      sovReqTokenCookie
    ) {
      const apiParams = new URLSearchParams();
      apiParams.append('sovReqToken', sovReqTokenCookie);
      apiParams.append('user_token', conversion.user_token);
      apiParams.append('rid', conversion.rid);
      apiParams.append('uid', conversion.uid);

      const trackPianoConversionUrl = `/track-piano-conversion/?${apiParams.toString()}`;
      let response = await fetch(trackPianoConversionUrl);
      response = await response.json();

      // don't track tealium user hasn't piano access and sovendus conversion is not tracked
      // @ts-ignore
      if (!response || !response.access) {
        return null;
      }

      doHandleTealiumSovendus('sovendus_conversion', {
        event_category: 'sovendus',
        event_action: 'attributed',
        event_label: conversion?.termId || undefined,
      });
    }
  }

  dispatchCustomEvent(eventName: string, eventInitDict?: EventInit) {
    const customEventName = `RASCH-${eventName}`;
    const raschCustomEvent = new Event(customEventName, eventInitDict);

    document.dispatchEvent(raschCustomEvent);

    log(
      'PIANO',
      [`dispatchCustomEvent "${customEventName}" dispatched`],
      'green',
    );
  }

  _getExperienceTemplatePlaceholder(conversion: Record<string, any>) {
    const pianoEventShowTemplate = conversion.result.events
      .filter(
        (event) =>
          event.eventType === 'showTemplate' &&
          (event.eventParams.containerSelector.includes(
            PIANO_CONTAINER_ARTICLE_ASIDE,
          ) ||
            event.eventParams.containerSelector.includes(
              PIANO_CONTAINER_LANDING_ASIDE,
            )),
      )
      .map((event) => event.eventParams.containerSelector);

    const allPIanoArticleAside = document.querySelectorAll(
      `[class*=${PIANO_PLACEHOLDER_ASIDE}]`,
    );

    allPIanoArticleAside?.forEach((pianoAsideElement) => {
      const isInTemplate: boolean = pianoEventShowTemplate.some(
        (selector: string) => pianoAsideElement.matches(selector),
      );

      if (!isInTemplate) {
        pianoAsideElement.classList.remove(PIANO_PLACEHOLDER_ASIDE);
      }
    });
  }

  _showExperienceDebugLogs(conversion: Record<string, any>) {
    const experiences = new Map();

    conversion.result.experiences.forEach((experience) => {
      experiences.set(experience.id, experience.title);
    });

    const events = conversion.result.events.map((event) => {
      const { experienceId } = event.eventExecutionContext;
      const experience = experiences.get(experienceId);

      return {
        eventType: event.eventType,
        moduleName: event.eventModuleParams.moduleName,
        experience,
      };
    });

    // eslint-disable-next-line no-console
    console.table(events, ['experience', 'moduleName', 'eventType']);
  }

  _initialize(): void {
    window.tp.push([
      'init',
      () => {
        // set z-indexes to defined values
        window.tp.push([
          'setZIndexes',
          {
            backdrop: pianoZIndex,
            modal: pianoModalZIndex,
            close: pianoModalZIndex + 1,
          },
        ]);

        window.tp.push([
          'setZone',
          (!__PIANO_FORCE_DISABLE__ && __PIANO_ENV__) || 'not enabled',
        ]);

        if (__PIANO_CXENSE_ID__) {
          window.tp.push(['setCxenseSiteId', `${__PIANO_CXENSE_ID__}`]);
        }

        // user login events
        window.tp.push([
          'addHandler',
          'loginRequired',
          this._onLoginRequired.bind(this),
        ]);

        window.tp.push([
          'addHandler',
          'checkoutComplete',
          (conversion) => {
            doHandleTealium('checkoutComplete', conversion);
            this.dispatchCustomEvent('checkoutComplete', conversion);

            this.checkoutState = false;
            if (typeof window.tp.experience.execute === 'function') {
              window.tp.experience.execute();
            }
            this._logSovendusConversion(conversion);
          },
        ]);

        window.tp.push([
          'addHandler',
          'checkoutCustomEvent',
          (conversion) => {
            doHandleTealium('checkoutCustomEvent', conversion);
            this.dispatchCustomEvent('checkoutCustomEvent', conversion);

            switch (conversion.eventName) {
              case 'acceptBankingDisclaimer':
                setCookie('_pc_banking_disclaimer', 'accepted', 365, '/');
                this._cleanUpExperiences();
                break;
              case 'gotoHome':
                this.checkoutState = false;
                this.props.navigate('/');
                break;

              case 'registerForPushNotifications':
                const isSlidedownPromptEnabled: boolean =
                  String(conversion.params?.isSlidedownPromptEnabled) ===
                  'true';

                this._registerForPushNotifications(isSlidedownPromptEnabled);
                break;

              case 'loginWithoutCheckout':
                const loginCase: LoginCase =
                  conversion.params?.logincase ||
                  __PIANO_LOGIN_CASE__ ||
                  AUTH0_LOGIN_CASE_EMAIL_ONLY;
                const source = doGetRCTrackingSource(
                  'piano-overlay',
                  this.props.pageMetadata,
                  {
                    experienceId: conversion.params.experienceId,
                    eventName: conversion.eventName,
                  },
                );

                Auth0.login(loginCase, source);
                break;

              case 'invitationFlowStart':
                if (
                  global?.tp?.offer &&
                  conversion?.params?.invitationModalId !== ''
                ) {
                  if (global.tp.user.isUserValid()) {
                    this.checkoutState = true;

                    global.tp.offer.startCheckout(conversion.params);

                    log('PIANO', ['startCheckout', conversion.params], 'green');
                  } else {
                    this.pianoConversionParams = {
                      experienceId: conversion?.params?.experienceId,
                      termId: conversion?.params?.termId,
                      offerId: conversion?.params?.offerId,
                      templateId: conversion?.params?.invitationModalId,
                      templateVariantId: conversion?.params?.templateVariantId,
                    };
                    global.tp.template.show({
                      templateId: conversion?.params?.invitationModalId,
                      offerId: conversion?.params?.offerId,
                      experienceId: conversion?.params?.experienceId,
                      displayMode: 'modal',
                    });
                  }
                }
                break;
              case 'invitationFlowCheckout':
                const loader = document.createElement('div');
                loader.id = 'invitation-loader-container';
                loader.innerHTML = `<div class="invitation-loader-overlay"></div><div class="invitation-spinner-wrapper"><svg class="invitation-spinner" width="100px" height="100px" viewBox="0 0 44 44" > <circle class="invitation-spinner-path" cx="22" cy="22" r="20" fill="none" strokeWidth="2" /></svg></div>`;
                global.tp.template.close();
                document.body.appendChild(loader);

                if (conversion?.params?.email) {
                  return Auth0.invite(
                    encodeURIComponent(conversion?.params?.email),
                  )
                    .then((invitationResponse) => {
                      let errorMessage = null;
                      loader.style.display = 'none';

                      switch (invitationResponse?.invitationStatus) {
                        case ONELOG_INVITATIONS_INVITATION_SENT:
                          this.pianoConversionParams.invitationFlowStatus =
                            'new_user';
                          Auth0.onInvite(invitationResponse?.userinfo);

                          if (conversion.params.termId === '') {
                            conversion.params.termId =
                              this.pianoConversionParams.termId || '';
                          }

                          global.tp.setExternalJWT(
                            invitationResponse?.userinfo.id_token,
                          );
                          if (conversion) {
                            this.checkoutState = true;
                            doHandleTealium(
                              'invitationFlowStatus',
                              this.pianoConversionParams,
                            );

                            global.tp.offer.startCheckout(
                              this.pianoConversionParams,
                            );

                            log(
                              'PIANO',
                              ['startCheckout', conversion.params],
                              'green',
                            );
                          }
                          break;
                        case ONELOG_INVITATIONS_USER_ALREADY_EXISTS:
                        case ONELOG_INVITATIONS_USER_EXISTS_OR_INVITATION_SENT:
                          this.pianoConversionParams.invitationFlowStatus =
                            invitationResponse?.invitationStatus ===
                            ONELOG_INVITATIONS_USER_ALREADY_EXISTS
                              ? 'existing_user'
                              : 'pending_invitation';
                          const loginCase: LoginCase =
                            conversion.params?.logincase ||
                            __PIANO_LOGIN_CASE__ ||
                            AUTH0_LOGIN_CASE_EMAIL_ONLY;
                          const source = doGetRCTrackingSource(
                            'piano-overlay',
                            this.props.pageMetadata,
                            {
                              experienceId: conversion.params.experienceId,
                              eventName: conversion.eventName,
                            },
                          );
                          doHandleTealium(
                            'invitationFlowStatus',
                            this.pianoConversionParams,
                          );
                          Auth0.login(
                            loginCase,
                            source,
                            '',
                            conversion?.params?.email,
                          );
                          break;
                        case ONELOG_INVITATIONS_EMAIL_CANNOT_BE_VERIFIED:
                          errorMessage =
                            this.props.messages.invitationUnverifiedEmail ||
                            this.fallbackMessages.invitationUnverifiedEmail;
                          break;
                        default:
                          errorMessage =
                            this.props.messages.invitationCommonError ||
                            this.fallbackMessages.invitationCommonError;
                          break;
                      }

                      if (
                        global?.tp?.offer &&
                        errorMessage &&
                        conversion?.params?.invitationErrorId !== ''
                      ) {
                        global.tp.push([
                          'setCustomVariable',
                          'errorMessage',
                          errorMessage,
                        ]);

                        global.tp.template.show({
                          templateId: conversion?.params?.invitationErrorId,
                          offerId: conversion?.params?.offerId,
                          displayMode: 'modal',
                        });
                      }
                    })
                    .catch(() => {
                      loader.style.display = 'none';

                      if (
                        global?.tp?.offer &&
                        conversion?.params?.invitationErrorId !== ''
                      ) {
                        global.tp.push([
                          'setCustomVariable',
                          'errorMessage',
                          this.props.messages.invitationCommonError ||
                            this.fallbackMessages.invitationCommonError,
                        ]);

                        global.tp.template.show({
                          templateId: conversion?.params?.invitationErrorId,
                          offerId: conversion?.params?.offerId,
                          displayMode: 'modal',
                        });
                      }
                    });
                }

                break;
              case 'navigateTo':
                this.checkoutState = false;
                const targetUri: string = conversion.params?.targetUri;
                const openInNewTab = conversion.params?.openInNewTab === 'true';
                const notOpenInNewTab =
                  conversion.params?.openInNewTab === 'false';

                if (targetUri) {
                  if (openInNewTab) {
                    window.open(targetUri, '_blank');
                  } else if (notOpenInNewTab) {
                    this.props.navigate(targetUri);
                  } else {
                    handleWysiwygLink(targetUri, this.props.navigate);
                  }
                }
                break;

              case 'openNativeAppStore':
                dispatchHybridAppEvent('openstore', {});
                break;

              case 'closeNotificationsPrompt':
              case 'cancelNotificationsPrompt':
                doHandleTealium(conversion.eventName, conversion.params);
                break;
              case 'newsletterSubscriptionClose':
                const newsLetterSubscription: HTMLElement | null =
                  document.getElementById(PIANO_CONTAINER_ANIMATED);
                if (newsLetterSubscription) {
                  newsLetterSubscription.classList.remove('bounce-in-bottom');
                }
                break;
              case 'closeWithSlideDownAnimation': // used by Specific Topics Overlay templates in BEO/HZ/SI
                const pianoContainer: HTMLElement | null =
                  document.getElementById(PIANO_CONTAINER_SLIDE_DOWN_ANIMATED);
                if (pianoContainer) {
                  pianoContainer.classList.add('slide-out-bottom');
                }
                setTimeout(() => {
                  if (typeof window.tp?.offer?.closeInline === 'function') {
                    window.tp.offer.closeInline(
                      `#${PIANO_CONTAINER_SLIDE_DOWN_ANIMATED}`,
                    );
                    if (pianoContainer) {
                      pianoContainer.classList.remove('slide-out-bottom');
                    }
                  }
                }, 300);
            }
          },
        ]);

        window.tp.push([
          'addHandler',
          'showTemplate',
          (conversion) => {
            doHandleTealium('showTemplate', conversion);
            this.dispatchCustomEvent('showTemplate', conversion);
            this._hideSkeletonForInlinePaywall(conversion);
            this._toggleViewportLock(conversion);
            this._performAnimation(conversion);
          },
        ]);

        window.tp.push([
          'addHandler',
          'showOffer',
          (conversion) => {
            doHandleTealium('showOffer', conversion);
            this.dispatchCustomEvent('showOffer', conversion);
            this._hideSkeletonForInlinePaywall(conversion);
            this._toggleViewportLock(conversion);
          },
        ]);

        window.tp.push([
          'addHandler',
          'experienceExecutionFailed',
          () => {
            this.dispatchCustomEvent('experienceExecutionFailed');
            // cleanup if experience fails
            this._cleanUpExperiences();
            log('PIANO', 'experienceExecutionFailed', 'red');
          },
        ]);

        window.tp.push([
          'addHandler',
          'checkoutError',
          (errorData: any) => {
            doHandleTealium('checkoutError', errorData);
            this.dispatchCustomEvent('checkoutError', errorData);
            this._adjustModalZIndex('checkoutError');
            this.checkoutState = false;
            this._toggleCheckoutClass(false);
            log('PIANO', `checkoutError: ${JSON.stringify(errorData)}`, 'red');
          },
        ]);

        window.tp.push([
          'addHandler',
          'checkoutClose',
          (conversion) => {
            doHandleTealium('checkoutClose', conversion);
            this.dispatchCustomEvent('checkoutClose', conversion);
            this._adjustModalZIndex('checkoutClose');
            this.checkoutState = false;
            this._clearViewportLock();
            this._toggleCheckoutClass(false);
          },
        ]);

        window.tp.push([
          'addHandler',
          PIANO_EVENT_CHECKOUT_START,
          (conversion) => {
            doHandleTealium(PIANO_EVENT_CHECKOUT_START, conversion);
            this.dispatchCustomEvent(PIANO_EVENT_CHECKOUT_START, conversion);
            this._adjustModalZIndex(PIANO_EVENT_CHECKOUT_START);
            this._toggleCheckoutClass(true);
          },
        ]);

        window.tp.push([
          'addHandler',
          'checkoutSelectTerm',
          (conversion) => {
            doHandleTealium('checkoutSelectTerm', conversion);
            this.dispatchCustomEvent('checkoutSelectTerm', conversion);
            this._adjustModalZIndex(PIANO_EVENT_CHECKOUT_START);
            this._toggleCheckoutClass(true);
          },
        ]);

        window.tp.push([
          'addHandler',
          'checkoutStateChange',
          (conversion) => {
            doHandleTealium('checkoutStateChange', conversion);
            this.dispatchCustomEvent('checkoutStateChange', conversion);
            if (conversion.stateName === 'offer') {
              this._toggleCheckoutClass(false);
            }
          },
        ]);

        window.tp.push([
          'addHandler',
          'meterActive',
          (conversion) => {
            doHandleTealium('meterActive', conversion);
            this.dispatchCustomEvent('meterActive', conversion);
          },
        ]);

        window.tp.push([
          'addHandler',
          'setResponseVariable',
          (conversion) => {
            log('PIANO', 'conversion ' + JSON.stringify(conversion));
            this.dispatchCustomEvent('setResponseVariable', conversion);
            if (conversion?.responseVariables?.isAccessGranted) {
              if (
                [
                  RESTRICTION_STATUS_PAID,
                  RESTRICTION_STATUS_REGISTERED,
                ].includes(this.props?.pageMetadata?.restrictionStatus)
              ) {
                this.props.setPianoAccesGranted(true);
              }
            } else if (conversion?.responseVariables?.webinarAccessGranted) {
              this.props.setPianoWebinarAccesGranted(true);
            }
          },
        ]);

        window.tp.push([
          'addHandler',
          'meterExpired',
          (conversion) => {
            doHandleTealium('meterExpired', conversion);
            this.dispatchCustomEvent('meterExpired', conversion);
          },
        ]);

        window.tp.push([
          'addHandler',
          'experienceExecute',
          (conversion) => {
            this.dispatchCustomEvent('experienceExecute', conversion);

            if (document.cookie && document.cookie.indexOf('RASCHDEBUG') > -1) {
              this._showExperienceDebugLogs(conversion);
            }
            this._getExperienceTemplatePlaceholder(conversion);
          },
        ]);

        // init experience on first load
        window.tp.experience.init();
        this.isInitialized = true;
        log('PIANO', 'experience.init', 'green');
      },
    ]);
  }

  _onLoginRequired(params: Record<string, any>) {
    if (window.tp && window.tp.user && window.tp.user.isUserValid()) {
      log('PIANO', ['_onLoginRequired user is already valid!!!!'], 'red');
      return;
    }

    doHandleTealium('loginRequired', params);
    this.dispatchCustomEvent('loginRequired', params);

    // add params to local storage
    if (storageAvailable('localStorage')) {
      localStorage.setItem(
        PIANO_LOCAL_STORAGE_PARAMS,
        JSON.stringify(params || {}),
      );
    }
    let loginCase: LoginCase =
      (__PIANO_LOGIN_CASE__ as LoginCase) || AUTH0_LOGIN_CASE_EMAIL_ONLY;

    // is a offer modal for paid terms
    if (params && !params.showCloseButton) {
      loginCase =
        (__PIANO_LOGIN_CASE_FULLNAME_REQUIRED__ as LoginCase) ||
        AUTH0_LOGIN_CASE_EMAIL_ONLY;
    }

    if (!params?.experienceId) {
      log(
        'PIANO',
        ['_onLoginRequired params.experienceId is not defined'],
        'red',
      );
    }

    const source = doGetRCTrackingSource(
      'piano-overlay',
      this.props.pageMetadata,
      {
        experienceId: params.experienceId,
        eventName: 'loginRequired',
      },
    );

    Auth0.login(loginCase, source);
  }

  _cleanUpExperiences() {
    if (
      window.tp &&
      window.tp.offer &&
      typeof window.tp.offer.close === 'function' &&
      typeof window.tp.offer.closeInline === 'function'
    ) {
      window.tp.offer.close();
      window.tp.offer.closeInline(`#${PIANO_CONTAINER_ANIMATED}`);
      window.tp.offer.closeInline(`#${PIANO_CONTAINER_LOCKED}`);
      window.tp.offer.closeInline(`#${PIANO_CONTAINER_METERING}`);
      window.tp.offer.closeInline(`#${PIANO_CONTAINER_SLIDE_DOWN_ANIMATED}`);
      window.tp.scrollDepth.clearMaxScrolledPosition();
    }

    this._clearViewportLock();
  }

  _addViewportLock() {
    const app: HTMLElement | null = document.getElementById('app');
    if (app) {
      disableBodyScroll(app);
    }
  }

  _clearViewportLock() {
    const app: HTMLElement | null = document.getElementById('app');
    if (app) {
      enableBodyScroll(app);
    }
  }

  _toggleViewportLock(params: Record<string, any>) {
    if (
      (params.displayMode === 'inline' &&
        params.containerSelector === `#${PIANO_CONTAINER_LOCKED}`) ||
      params.displayMode === 'modal'
    ) {
      this._addViewportLock();
    } else {
      this._clearViewportLock();
    }
  }

  _performAnimation(params: Record<string, any>) {
    if (params.containerSelector === `#${PIANO_CONTAINER_ANIMATED}`) {
      // we set a timeout here, to make sure that the piano template is loaded before we perform the animation
      setTimeout(() => {
        const newsLetterSubscription: HTMLElement | null =
          document.getElementById(PIANO_CONTAINER_ANIMATED);

        if (
          newsLetterSubscription &&
          !newsLetterSubscription.classList.contains('bounce-in-bottom')
        ) {
          newsLetterSubscription.classList.add('bounce-in-bottom');
        }
      }, 1000);
    }
  }

  async _detectIncognitoMode(): Promise<boolean> {
    return new Promise((resolve) => {
      // is in private mode
      const privateMode = () => resolve(true);

      // not in private mode
      const notPrivateMode = () => resolve(false);

      // Chrome & Opera adjusted to cope with this issue
      // https://stackoverflow.com/questions/2860879/detecting-if-a-browser-is-using-private-browsing-mode#targetText=Google%20is%20removing%20the%20ability,permanently%20in%20Chrome%2076%20onwards.&targetText=To%20anyone%20else%20coming%20across,mode%20through%20Javascript%20or%20CSS.
      const fs = window.webkitRequestFileSystem || window.RequestFileSystem;
      if (fs) {
        return (async () => {
          if ('storage' in navigator && 'estimate' in navigator.storage) {
            const { quota } = await navigator.storage.estimate();
            if (quota < 120000000) {
              return privateMode();
            }

            return notPrivateMode();
          }

          return notPrivateMode();
        })();
      }

      // Firefox
      if (
        document.documentElement &&
        'MozAppearance' in document.documentElement.style
      ) {
        if (window.indexedDB === null) {
          return privateMode();
        }

        const db = window.indexedDB.open('test');
        db.onerror = privateMode;
        db.onsuccess = notPrivateMode;
        return void 0;
      }

      // Safari
      const safariVersionData: Record<string, any> = safariVersion();

      if (safariVersionData) {
        const version = parseInt(safariVersionData[1], 10);

        if (version < 11) {
          try {
            if (localStorage.length) {
              return notPrivateMode();
            }

            localStorage.setItem('x', '1');
            localStorage.removeItem('x');
            return notPrivateMode();
          } catch (_) {
            // Safari only enables cookie in private mode
            // if cookie is disabled, then all client side storage is disabled
            // if all client side storage is disabled, then there is no point
            // in using private mode
            if (window.navigator.cookieEnabled) {
              return privateMode();
            }

            return notPrivateMode();
          }
        }

        try {
          window.openDatabase(null, null, null, null);
          return notPrivateMode();
        } catch (_) {
          return privateMode();
        }
      }

      // IE10+ & Edge InPrivate
      // @ts-ignore
      if (!window.indexedDB && (window.PointerEvent || window.MSPointerEvent)) {
        return privateMode();
      }

      // default navigation mode
      return notPrivateMode();
    });
  }

  async _getUserNotificationsSettings(): Promise<UserNotificationsSettings> {
    const userNotificationSettings: UserNotificationsSettings = {
      isPushNotificationsSupported: false,
      isPushNotificationsEnabled: false,
      notificationsPermission: 'default',
    };

    return new Promise((resolve) => {
      if (!global.OneSignal) {
        return resolve(userNotificationSettings);
      }

      global.OneSignal.push(async () => {
        global.OneSignal.on(
          'notificationPermissionChange',
          async (permissionChange) => {
            this.props.setPianoBrowserMetadata({
              browserMode: this.props.browserMetadata.browserMode,
              isPushNotificationsSupported:
                global.OneSignal.isPushNotificationsSupported(),
              isPushNotificationsEnabled:
                await global.OneSignal.isPushNotificationsEnabled(),
              notificationsPermission: permissionChange.to,
            });
          },
        );

        global.OneSignal.on(
          'subscriptionChange',
          (isPushNotificationsEnabled: boolean) => {
            if (isPushNotificationsEnabled) {
              global.OneSignal.sendTag('news', 'true');
            } else {
              global.OneSignal.deleteTag('news');
            }

            this.props.setPianoBrowserMetadata({
              browserMode: this.props.browserMetadata.browserMode,
              isPushNotificationsSupported:
                global.OneSignal.isPushNotificationsSupported(),
              isPushNotificationsEnabled: isPushNotificationsEnabled,
              notificationsPermission:
                this.props.browserMetadata.notificationsPermission,
            });
          },
        );

        userNotificationSettings.isPushNotificationsSupported =
          global.OneSignal.isPushNotificationsSupported();
        userNotificationSettings.isPushNotificationsEnabled =
          await global.OneSignal.isPushNotificationsEnabled();
        userNotificationSettings.notificationsPermission =
          await global.OneSignal.getNotificationPermission();

        return resolve(userNotificationSettings);
      });
    });
  }

  _registerForPushNotifications(isSlidedownPromptEnabled: boolean) {
    if (!global.OneSignal) {
      return;
    }

    global.OneSignal.push(() => {
      if (isSlidedownPromptEnabled) {
        global.OneSignal.showSlidedownPrompt();
        return;
      }

      global.OneSignal.registerForPushNotifications();
    });
  }

  // With an active adblocker we'll not be able to fetch this file correctly
  async _isAdScriptBlocked() {
    if (typeof this.isAdblockerActive === 'undefined') {
      const scripts = document.getElementsByTagName('script');
      // using a regex here to keep the file path dynamic
      //(version handling in url and ast.js files can also come from another host)
      const astFileSrc = [...scripts].filter((test) =>
        test.src.match(/acdn\.adnxs\.com\/.*ast\.js/),
      );
      this.astFilePath = astFileSrc[0]?.src || '';

      try {
        const atmResponse = await fetch(this.astFilePath);
        if (atmResponse.status !== 200) {
          return true;
        }

        return false;
      } catch (error) {
        return true;
      }
    }
    if (!this.astFilePath) {
      return;
    }
  }

  componentDidMount() {
    // init tp on global scope
    window.tp = window.tp || [];

    // Does application use Piano ID?
    window.tp.push(['setUseTinypassAccounts', false]);
    window.tp.push(['setUsePianoIdUserProvider', false]);
    window.tp.push(['setUsePianoIdLiteUserProvider', true]);
    // Set Application ID
    window.tp.push(['setAid', __PIANO_AID__]);
    window.tp.push(['setDebug', false]);

    // Is application in sandbox?
    if (__PIANO_ENDPOINT__ === 'https://sandbox.tinypass.com/api/v3') {
      window.tp.push(['setSandbox', true]);
    }

    window.tp.push(['setEndpoint', __PIANO_ENDPOINT__]);

    // load browser data
    this._getBrowserMetadata();

    // Detects if page is in standalone (PWA installed) mode on iOS
    const isInStandaloneMode = global.navigator['standalone'];
    window.tp.push(['setCustomVariable', 'appInstalled', isInStandaloneMode]);
    window.tp.push([
      'setCustomVariable',
      'isIosDevice',
      // @ts-ignore
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
    ]);
  }

  async componentDidUpdate() {
    log('PIANO', ['in component did update', this.props], 'green');
    if (this.props.isCrawler) {
      log('PIANO', 'blocked - isCrawler', 'red');
      return;
    }
    Object.assign(this.currentProps, this.props);
    const {
      channelsHierarchy,
      contentType,
      gcid,
      publication,
      isNativeContent,
      publicationDate,
      restrictionStatus,
      section,
      tags,
      isPrintArticle,
      mainChannel,
      subType,
      webinarId,
      cliffhangerTitle,
      cliffhangerBulletpoints,
    } = this.props.pageMetadata;

    window.tp.push([
      'setCustomVariable',
      'restrictionStatus',
      restrictionStatus,
    ]);

    channelsHierarchy.forEach((channelHierarchy, index) => {
      window.tp.push([
        'setCustomVariable',
        `channelLevel${index + 1}`,
        channelHierarchy,
      ]);
    });

    window.tp.push(['setCustomVariable', 'gcid', gcid]);

    window.tp.push([
      'setCustomVariable',
      'pagePath',
      global.location.pathname + global.location.search,
    ]);

    window.tp.push(['setCustomVariable', 'publication', publication]);

    window.tp.push(['setCustomVariable', 'contentType', contentType]);

    window.tp.push(['setCustomVariable', 'mainChannel', mainChannel]);

    window.tp.push(['setTags', tags]);

    window.tp.push(['setContentCreated', publicationDate]);
    window.tp.push(['setContentSection', section]);
    window.tp.push(['setContentIsNative', isNativeContent]);

    window.tp.push([
      'setCustomVariable',
      'externalSubscription',
      this.props.userMetadata.externalSubscription,
    ]);

    window.tp.push(['setCustomVariable', 'cliffhangerTitle', cliffhangerTitle]);

    window.tp.push([
      'setCustomVariable',
      'benefitsList',
      cliffhangerBulletpoints?.join(' | '),
    ]);

    const {
      browserMode,
      isPushNotificationsSupported,
      isPushNotificationsEnabled,
      notificationsPermission,
    } = this.props.browserMetadata;

    window.tp.push(['setCustomVariable', 'browserMode', browserMode]);

    window.tp.push([
      'setCustomVariable',
      'isPushNotificationsSupported',
      isPushNotificationsSupported,
    ]);

    window.tp.push([
      'setCustomVariable',
      'isPushNotificationsEnabled',
      isPushNotificationsEnabled,
    ]);

    window.tp.push([
      'setCustomVariable',
      'notificationsPermission',
      notificationsPermission,
    ]);

    window.tp.push(['setCustomVariable', 'isPrintArticle', isPrintArticle]);

    window.tp.push(['setCustomVariable', 'subType', subType]);

    if (webinarId) {
      window.tp.push(['setCustomVariable', 'webinarId', webinarId]);
    }

    if (this.props.isHybridApp) {
      window.tp.push([
        'setCustomVariable',
        'hybridApp',
        this.props.isHybridApp,
      ]);
    }

    window.tp.push([
      'setCustomVariable',
      'userAgent',
      global?.navigator?.userAgent,
    ]);

    if (this.props.userMetadata.idToken) {
      window.tp.push(['setExternalJWT', this.props.userMetadata.idToken]);
    }

    if (window.tp?.user && window.tp.user.isUserValid()) {
      let params: Record<string, any> | null;
      let preventCheckoutExperience = '';

      if (storageAvailable('localStorage')) {
        params = JSON.parse(
          localStorage.getItem(PIANO_LOCAL_STORAGE_PARAMS) || 'null',
        );
        // remove item after getting the value
        localStorage.removeItem(PIANO_LOCAL_STORAGE_PARAMS);
        preventCheckoutExperience =
          PIANO_ALREADY_HAVE_ACCESS_EXPERIENCES[__PIANO_AID__] || '';
      } else {
        params = null;
      }

      // If params object is valid - start checkout
      if (params) {
        if (!this.isInitialized) {
          this._initialize();
        }
        if (
          this.props.userMetadata.externalSubscription !== '' &&
          preventCheckoutExperience !== ''
        ) {
          window.tp.template.show({
            displayMode: 'modal',
            templateId: preventCheckoutExperience,
          });
          log(
            'PIANO',
            [
              'prevent checkout for external subscriber',
              preventCheckoutExperience,
            ],
            'green',
          );
          return;
        }
        this.checkoutState = true;
        doHandleTealium('startCheckout', params);
        window.tp.offer.startCheckout(params);
        log('PIANO', ['startCheckout', params], 'green');
        return;
      }
    }

    if (!this.isInitialized) {
      this._initialize();
      return;
    }

    this._cleanUpExperiences();

    if (window.tp?.experience) {
      // adblocked status
      this.isAdblockerActive = await this._isAdScriptBlocked();

      window.tp.push([
        'setCustomVariable',
        'isAdblockerActive',
        this.isAdblockerActive,
      ]);

      if (typeof window.tp.experience.execute === 'function') {
        window.tp.experience.execute();
        log('PIANO', 'experience.execute', 'green');
      } else {
        log('PIANO', 'experience.execute failed', 'red');
      }
    } else {
      log('PIANO', 'experience failed', 'red');
    }
  }

  shouldComponentUpdate(nextProps: PianoPropsInner) {
    log('PIANO', ['in should update', this.props, nextProps], 'orange');

    // Piano not ready
    if (
      !window.tp ||
      this.checkoutState ||
      !nextProps.userMetadata.initialAuthRequest ||
      !nextProps.isScriptLoaded ||
      !nextProps.screenReady ||
      // we want to update only when we have correct metadata information
      nextProps.pageMetadata.notInitialized
    ) {
      log('PIANO', ['in should update - no'], 'red');
      return false;
    }

    if (!this.currentProps) {
      this.currentProps = JSON.parse(JSON.stringify(this.props));
    }

    // UserMetadata & PageMetadata or isAdblockerActive has changed
    if (
      JSON.stringify(nextProps.userMetadata) !==
        JSON.stringify(this.currentProps.userMetadata) ||
      JSON.stringify(nextProps.pageMetadata) !==
        JSON.stringify(this.currentProps.pageMetadata)
    ) {
      log('PIANO', ['in should update - yes'], 'green');
      return true;
    }

    // check screenReady & isScriptLoaded for SSR (locally screenReady would be sufficent)
    return (
      this.currentProps.screenReady !== nextProps.screenReady ||
      this.currentProps.isScriptLoaded !== nextProps.isScriptLoaded
    );
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render(): null {
    return null;
  }
}

const mapStateToProps = (state) => ({
  pageMetadata: pianoStateSelector(state).pageMetadata,
  userMetadata: pianoStateSelector(state).userMetadata,
  browserMetadata: pianoStateSelector(state).browserMetadata,
  screenReady: locationStateSelector(state).screenReady,
  isCrawler: locationStateSelector(state).isCrawler,
  isHybridApp: locationStateSelector(state).isHybridApp,
});

const mapDispatchToProps: Record<string, any> = {
  setPianoBrowserMetadata,
  setPianoAccesGranted,
  setPianoWebinarAccesGranted,
};

const withStoreConnection = connect(mapStateToProps, mapDispatchToProps);

export const withScriptLoader: Function = scriptLoader(
  'https://cdn.tinypass.com/api/tinypass.min.js',
);

export default __PIANO_AID__ && __PIANO_ENDPOINT__
  ? compose(withNavigate, withScriptLoader, withStoreConnection)(Piano)
  : (): null => null;

type DoHandleTealium = (
  event: string,
  pianoParams: Record<string, any>,
) => void;

const doHandleTealium: DoHandleTealium = (event, pianoParams) => {
  tealiumTrackEvent({
    type: 'link',
    payload: {
      event_name: event,
      piano_params: pianoParams,
    },
  });
};

const doHandleTealiumSovendus: DoHandleTealium = (event, params) => {
  tealiumTrackEvent({
    type: 'link',
    payload: {
      event_name: event,
      ...params,
    },
  });
};

const doGetRCTrackingSource = (registrationCase, pageMetaData, pianoProps) =>
  getRCTrackingSource(registrationCase, pageMetaData, pianoProps);
