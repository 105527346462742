import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { requestIdleCallback } from '../../shared/helpers/requestIdleCallback';
import { log } from '../../shared/helpers/utils';
import { isTouchDeviceSelector } from '../../shared/selectors/windowStateSelector';

type TaskHandler = (taskData?: Record<string, any>) => void;
type Task = {
  handler: TaskHandler;
  data: Record<string, any>;
};
type GQLQueryObject = {
  operation: string;
  query: any;
  variables: Record<string, any>;
};

const isIntersectionObserverSupported: boolean =
  'IntersectionObserver' in global && __CLIENT__;

const taskList: Task[] = [];
let taskHandle: any = 0;

const runTaskQueue = (deadline: RequestIdleCallbackDeadline): void => {
  while (
    (deadline.timeRemaining() > 0 || deadline.didTimeout) &&
    Array.isArray(taskList) &&
    taskList.length
  ) {
    const task = taskList.shift();

    task.handler(task.data);
  }

  if (taskList.length) {
    taskHandle = requestIdleCallback(runTaskQueue, { timeout: 1000 });
  } else {
    taskHandle = 0;
  }
};

const enqueueTask = (
  taskHandler: TaskHandler,
  taskData?: Record<string, any>,
) => {
  Array.isArray(taskList) &&
    taskList.push({
      handler: taskHandler,
      data: taskData,
    });

  if (!taskHandle) {
    taskHandle = requestIdleCallback(runTaskQueue, { timeout: 1000 });
  }
};

export default (
  enabled: boolean,
  ref: React.MutableRefObject<any>,
  gqlQuery: GQLQueryObject,
) => {
  if (!enabled || __SERVER__) {
    return;
  }
  const isTouchDevice = useSelector(isTouchDeviceSelector);
  const observerRef = useRef(null);

  useEffect(() => {
    const handlePrefetching = () => {
      enqueueTask(() => {
        if (global?.apolloClient) {
          global.apolloClient.query(gqlQuery);
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          detach();
        }
      });
    };

    const detach = () => {
      if (isIntersectionObserverSupported && isTouchDevice) {
        observerRef?.current?.disconnect();
      } else {
        ref?.current?.removeEventListener('mouseover', handlePrefetching);
      }
    };

    if (isIntersectionObserverSupported && isTouchDevice) {
      const options = {
        threshold: 1.0,
      };

      observerRef.current = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting) {
          return;
        }
        handlePrefetching();
      }, options);

      try {
        if (observerRef && observerRef.current && ref && ref.current) {
          observerRef.current.observe(ref.current);
        }
      } catch (error) {
        log(
          'useContentPrefetching',
          `An error occured while trying to observe ${error}`,
          'red',
        );
      }
    } else {
      ref?.current?.addEventListener('mouseover', handlePrefetching);
    }

    // Specify how to clean up after this effect:
    return () => detach();
  }, [isTouchDevice, ref, gqlQuery]);
};
