import React, { ReactElement } from 'react';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import { ImgProps } from './typings';

const Img = ({
  title,
  alt = '',
  children,
  height,
  url,
  width,
  addClass = '',
}: ImgProps): ReactElement => {
  //  Key is needed to wait until image is loaded SI-224
  const imgTag: ReactElement = (
    <img
      key={`image-key-${url || ''}`}
      className={!!addClass ? addClass : null}
      src={url}
      title={title || null}
      alt={alt}
      width={width || null}
      height={height || null}
      data-testid="image-wrapper"
    />
  );

  if (children) {
    return (
      <TestFragment data-testid="image-children-wrapper">
        {imgTag}
        {children}
      </TestFragment>
    );
  }

  return imgTag;
};

export default Img;
