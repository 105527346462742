import { Component } from 'react';
import scriptLoader from 'react-async-script-loader';
import { noop } from '../../../shared/helpers/utils';

type OneSignalProviderPropsInner = {
  isScriptLoaded: boolean;
  isScriptLoadSucceed: boolean;
  onScriptLoaded: Function;
};

class OneSignalProvider extends Component<OneSignalProviderPropsInner> {
  constructor(props: OneSignalProviderPropsInner) {
    global.OneSignal = global.OneSignal || [];
    global.OneSignal.push(() => {
      global.OneSignal.init({
        appId: __DEVELOPMENT__
          ? __DEV_ONESIGNAL_APP_ID__
          : __ONESIGNAL_APP_ID__,
        allowLocalhostAsSecureOrigin: __DEVELOPMENT__,
        autoRegister: false,
        notifyButton: {
          enable: false,
        },
        welcomeNotification: {
          title: 'Willkommen!',
          message: 'Gerne halten wir dich auf dem Laufenden.',
        },
        promptOptions: {
          actionMessage:
            'Abonniere unsere Push-Mitteilungen und verpasse nie mehr Breaking News',
          acceptButtonText: 'Ja, gerne',
          cancelButtonText: 'Nein, danke',
          slidedown: {
            enabled: false,
            autoPrompt: false,
          },
        },
        autoResubscribe: true,
      });
    });

    super(props);
  }

  render() {
    return null;
  }
}

export default __ONESIGNAL_APP_ID__ && __CLIENT__
  ? scriptLoader('//cdn.onesignal.com/sdks/OneSignalSDK.js')(OneSignalProvider)
  : noop;
